<script>
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { mapGetters, mapActions } from 'vuex';

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      newPassword: null,
      result: 0
    };
  },
  props: {
    token: {
      type: String
    },
    userId: {
      type: String
    }
  },
  validations() {
    const rules = {
      newPassword: { required }
    };
    return rules;
  },
  computed :{
    ...mapGetters('auth', {
      processing: 'processing'
    }),
  },
  methods: {
    ...mapActions('auth', {
      setNewPassword: 'setNewPassword'
    }),
    async submit () {
      const isValid = await this.v$.$validate();
      if(isValid) {
        const {  newPassword, token, userId } = this;
        this.setNewPassword({ newPassword, token, userId })
          .then(() => {
            this.result = 2;
            window.setTimeout(()=>{
              this.$router.push('/login');
            }, 2000);
         
          })
          .catch(() => {
            this.result = 1;
          });
      }
    }
  }
}
</script>

<template>
 <div class="auth-page">
      <div class="container-fluid p-0">
          <div class="row g-0 align-items-center">
              <div class="col-xxl-4 col-lg-4 col-md-6">
                  <div class="row justify-content-center g-0">
                      <div class="col-xl-9">
                          <div class="p-4">
                              <div class="card mb-0">
                                  <div class="card-body">
                                      <div class="auth-full-page-content rounded d-flex p-3 my-2">
                                          <div class="w-100">
                                              <form class="d-flex flex-column h-100">
                                                
                                                  <div class="mb-4 mb-md-5">
                                                      <router-link to="/" class="d-block auth-logo">
                                                          <img src="@/assets/images/logo-dark.png" alt="" height="22" class="auth-logo-dark me-start">
                                                          <img src="@/assets/images/logo-light.png" alt="" height="22" class="auth-logo-light me-start">
                                                      </router-link>
                                                  </div>
                                                  <div class="auth-content my-auto" v-if="result === 2">
                                                    <div class="text-center">
                                                      <h5 class="mb-0">Set New Password</h5>
                                                      <p class="text-muted mt-2">Set New Password with VostokSOS CRM.</p>
                                                    </div>
                                                    <div class="alert alert-success text-center my-4 font-size-12" role="alert">
                                                      <p>
                                                         Your password was successfully changed. <br/>Please <router-link to="/login" class="text-primary fw-semibold"> login </router-link>.
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="auth-content my-auto" v-if="result === 1">
                                                    <div class="text-center">
                                                      <h5 class="mb-0">Set New Password</h5>
                                                      <p class="text-muted mt-2">Set New Password with VostokSOS CRM.</p>
                                                    </div>
                                                    <div class="alert alert-warning text-center my-4 font-size-12" role="alert">
                                                      <p>
                                                        Your token has expired.<br/>
                                                         Try again. <router-link to="/recoverpwd" class="text-primary fw-semibold"> Reset password </router-link>
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="auth-content my-auto" v-if="result === 0">
                                                      <div class="text-center">
                                                          <h5 class="mb-0">Set New Password</h5>
                                                          <p class="text-muted mt-2">Set New Password with VostokSOS CRM.</p>
                                                      </div>
                                                      <div class="alert alert-info text-center my-4 font-size-12" role="alert">
                                                          Enter your New Password.
                                                      </div>
                                                      <form class="mt-4 pt-2">
                                                          <div class="mb-4">
                                                              <label for="input-email">New Password</label>
                                                              <input type="password" class="form-control" id="input-email" placeholder="Enter New Password" v-model="newPassword" :class="{ 'is-invalid': v$.newPassword.$error }">
                                                              <div class="invalid-feedback">
                                                                  Please Enter New Password
                                                              </div> 
                                                          </div>
                                                          <div class="mb-3">
                                                            <button class="btn btn-primary w-100 waves-effect waves-light" type="button" @click="submit" :disabled="processing">
                                                              <i class="fa fa-spin fa-spinner me-2" v-if="processing"></i>
                                                              Submit
                                                            </button>
                                                          </div>
                                                      </form>
                  
                                                      <div class="mt-4 pt-3 text-center">
                                                          <p class="text-muted mb-0">Remember Password ?  <router-link to="/login"
                                                              class="text-primary fw-semibold"> Login </router-link> </p>
                                                      </div>
                                                  </div>
                                                  <div class="mt-4 text-center">
                                                      <p class="mb-0">© {{new Date().getFullYear()}} VostokSOS CRM. </p>
                                                  </div>
                                              </form>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- end auth full page content -->
              </div>
              <!-- end col -->
              <div class="col-xxl-8 col-lg-8 col-md-6">
                  <div class="auth-bg bg-white py-md-5 p-4 d-flex">
                      <div class="bg-overlay bg-white"></div>
                      <!-- end bubble effect -->
                      <div class="row justify-content-center align-items-center">
                          <div class="col-xl-8">
                              <div class="mt-4">
                                  <img src="@/assets/images/login-img.png" class="img-fluid" alt="">
                              </div>
                              
                          </div>
                      </div>
                  </div>
              </div>
              <!-- end col -->
          </div>
          <!-- end row -->
      </div>
      <!-- end container fluid -->
  </div>
</template>